export const requestTimeout = 5000
export const baseURL = process.env.API_URL || "https://pokeapi.co/api/v2"
export const theme = {
  color: {
    dark: '#000000',
    light: '#FFFFFF',
  },
};

export const defaultLinks = [
  {
    id: 0,
    path: "/",
    name: "Beranda",
  },
  // {
  //   id: 1,
  //   path: "/about/",
  //   name: "tentang kami",
  // },
  {
    id: 2,
    path: "https://bpklopiga.mygostore.com",
    name: "Pesan Online",
    highlight: true,
  },
  {
    id: 3,
    path: "/menu",
    name: "Menu",
  },
  {
    id: 4,
    path: "/kontak",
    name: "Kontak",
  },
];